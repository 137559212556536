<template lang="pug">
.items-wrap(id="arrange")
    .i-title 交付安排
    .cont.delivery
        .item(v-for="(item,index) in dates", :key="item.id")
            .name {{item.type.name}}：
            .date {{item.date}}
</template>

<script>
export default {
    name: '',
    props: {
        dates: {
            type: Array,
            default() {
                return [];
            }
        },
        stage: {
            type: Number,
            default: 0
        }
    },
};
</script>

<style lang="sass" scoped>
    .delivery
        // padding: 18px 70px
        padding: 0
        border-radius: 0
        border: none
        margin-bottom: 100px
        overflow: inherit
        .item
            padding-bottom: 48px
            position: relative
            padding-left: 25px
            border-left: 1px dashed #979797
            margin-bottom: 0 !important
            &:last-child
                padding-bottom: 0
                margin-bottom: 0
                height: 12px
            &:before
                left: -6px
                top: 0
                position: absolute
                content: ' '
                display: block
                background: #676767
                width: 12px
                height: 12px
                border-radius: 50%
        .name
            position: relative
            top: -4px
            display: inline-block
            color: #696D74
            text-align: center
        .date
            position: relative
            top: -4px
            display: inline-block
            font-size: 14px
</style>