<template lang="pug">
    .items-wrap(v-if="(businessInfo.ticket && businessInfo.ticket.to_freelancer_txt_desc) || (businessInfo.to_freelancer_addition_info)")
        .item(id="text_client_like")
            .i-title 给自由职业者的说明
            .cont.txt
                .p
                    pre.cont {{businessInfo.ticket.to_freelancer_txt_desc}}
                    <br>
                    pre.cout(v-html="businessInfo.to_freelancer_addition_info")
</template>

<script>
export default {
    name: '',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        },
    },
};
</script>

<style lang="sass" scoped>
    .txt
        background: #FFFFFF
        border: 1px solid #EAECEF
        border-radius: 4px
        font-size: 14px
        color: #696D74
        // max-height: 268px
        // overflow-y: scroll
        padding: 20px
        .p
            margin-bottom: 20px
            &:last-child
                margin-bottom: 0
        .sign
            font-size: 14px
            color: #1B1919
            margin-bottom: 20px
        .name
            color: #1B1919
            margin-bottom: 2px
        .cont
            color: #696D74
            line-height: 22px
</style>