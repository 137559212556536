import { render, staticRenderFns } from "./text_asset.vue?vue&type=template&id=7d19342e&scoped=true&lang=pug&"
import script from "./text_asset.vue?vue&type=script&lang=js&"
export * from "./text_asset.vue?vue&type=script&lang=js&"
import style0 from "./text_asset.vue?vue&type=style&index=0&id=7d19342e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d19342e",
  null
  
)

export default component.exports