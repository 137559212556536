import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Index from '../pages/index/index.vue';
const routes = [

    { path: '/:id/:stage', component: Index },
];


export const router = new Router({
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        selector: to.hash,
                        behavior: 'smooth',
                        offset: { y: 60 }
                    });
                }, 0);
            });
        }
        return { x: 0, y: 0 };
    }
});