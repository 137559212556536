<template lang="pug">
    .items-wrap(v-if="businessInfo.text_web_asset")
        .i-title 其他特别要求
        .item(id="other-req-text", v-if="businessInfo.text_web_asset")
            pre.normal {{businessInfo.text_web_asset}}

</template>

<script>
export default {
    name: '',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        }
    },
};
</script>

<style lang="sass" scoped>
    .i-title

    .normal
        padding: 30px
        font-size: 14px
        color: #696D74
        max-height: 268px
        overflow-y: scroll
        border: 1px solid #EAECEF
        border-radius: 4px
        line-height: 20px
    .other-req
        color: #222
        padding: 20px 0px 40px
        display: flex
        justify-content: space-around
        .list
            text-align: center
            background: #FFFFFF
            border: 1px solid #F7F7F7
            box-shadow: 0 2px 2px 0 rgba(235,235,235,0.50)
            border-radius: 7px
            width: 144px
            height: 93px
            display: flex
            justify-content: center
            flex-direction: column
            .desc
                font-size: 12px
                color: #696D74
                margin-bottom: 10px
            .cont
                font-size: 14px
                color: #1B1919
                font-weight: 500
</style>