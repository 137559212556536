<template lang="pug">
    .items-wrap(v-if="desc_text")
        .item(id="asset_file_desc")
            //- .i-title 已有文件素材说明
            .cont.txt
                .p
                    pre.cont {{desc_text}}
</template>

<script>
export default {
    name: 'TextAssetDesc',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        },
        desc_text:{}
    },
};
</script>

<style lang="sass" scoped>
    .txt
        margin-top: -100px
        background: #FFFFFF
        border: 1px solid #EAECEF
        border-radius: 4px
        font-size: 14px
        color: #696D74
        max-height: 268px
        overflow-y: scroll
        padding: 20px
        .p
            margin-bottom: 20px
            &:last-child
                margin-bottom: 0
        .sign
            font-size: 14px
            color: #1B1919
            margin-bottom: 20px
        .name
            color: #1B1919
            margin-bottom: 2px
        .cont
            color: #696D74
            line-height: 18px
</style>