<template lang="pug">
    .items-wrap
        template(v-for="item in pics")
            template(v-if="item.id === id")
                .item(:id="'picture_types_' + item.id")
                    .i-title {{item.name}}
                    .cont.slider
                        template(v-if="item.pictures.length <= 3")
                            .imgs.center
                                template(v-for="(pics, i) in item.pictures")
                                    div.imgs-solid
                                        el-image.img(
                                            :src="pics.attachment.pub_url",
                                            :preview-src-list="[pics.attachment.pub_url]"
                                        )
                                        span.new(v-if="showDetail && item.pictures.length === (i + 1)") New
                                        span.time(v-if="showDetail")
                                            <svg class="icon" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6 0C2.685 0 0 2.685 0 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6zm0 10.839A4.837 4.837 0 011.161 6 4.837 4.837 0 016 1.161 4.837 4.837 0 0110.839 6 4.837 4.837 0 016 10.839zm1.495-2.526L5.441 6.82a.292.292 0 01-.118-.235V2.613c0-.16.13-.29.29-.29h.774c.16 0 .29.13.29.29V6.04l1.617 1.176a.29.29 0 01.062.406l-.454.627a.292.292 0 01-.407.063z" fill="#4A4A4A" fill-rule="nonzero"/></svg>
                                            | 交付时间：{{pics.updated_at}}
                                        span.op(v-if="showDetail && pics.comment_content", @click="openCmt(pics.comment_content)")
                                            <svg class="icon" width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg"><path d="M11.082 7.38c.573-.565.917-1.274.917-2.047 0-1.666-1.594-3.043-3.67-3.29C7.671.844 6.13 0 4.332 0 1.939 0 0 1.492 0 3.333c0 .771.344 1.48.917 2.046A5.455 5.455 0 01.13 6.523a.48.48 0 00-.091.52.48.48 0 00.441.292c1.115 0 2.015-.42 2.608-.808.192.044.39.077.592.102.654 1.196 2.19 2.038 3.985 2.038.433 0 .85-.05 1.246-.142.593.385 1.491.808 2.608.808a.482.482 0 00.35-.812 5.31 5.31 0 01-.788-1.142zM2.9 5.455l-.356.231c-.294.19-.594.34-.898.446.056-.098.112-.202.166-.308l.323-.648-.516-.51C1.337 4.387 1 3.93 1 3.333 1 2.07 2.527 1 4.333 1c1.806 0 3.333 1.069 3.333 2.333 0 1.265-1.527 2.334-3.333 2.334-.344 0-.687-.04-1.02-.117L2.9 5.456zm7.48 1.21l-.514.509.323.648c.054.106.11.21.166.308a4.238 4.238 0 01-.898-.446l-.356-.23-.414.095a4.539 4.539 0 01-1.021.117c-1.125 0-2.129-.42-2.735-1.036 2.11-.225 3.735-1.612 3.735-3.298 0-.07-.009-.14-.015-.208C9.993 3.427 11 4.308 11 5.333c0 .598-.338 1.054-.619 1.334z" fill="#4A4A4A" fill-rule="nonzero"/></svg>
                                            | 查看修改意见
                        template(v-else)
                            el-carousel(:interval="5000", arrow="always" type="card", height="200", :autoplay="false", :loop="false")
                                el-carousel-item(v-for="(pics, i) in item.pictures", :key="pics.id")
                                    .imgs
                                        .imgs-solid
                                            el-image.img(
                                                :src="pics.attachment.pub_url",
                                                :preview-src-list="[pics.attachment.pub_url]"
                                            )
                                            span.new(v-if="showDetail && item.pictures.length === (i + 1)") New
                                            span.time(v-if="showDetail")
                                                <svg class="icon" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6 0C2.685 0 0 2.685 0 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6zm0 10.839A4.837 4.837 0 011.161 6 4.837 4.837 0 016 1.161 4.837 4.837 0 0110.839 6 4.837 4.837 0 016 10.839zm1.495-2.526L5.441 6.82a.292.292 0 01-.118-.235V2.613c0-.16.13-.29.29-.29h.774c.16 0 .29.13.29.29V6.04l1.617 1.176a.29.29 0 01.062.406l-.454.627a.292.292 0 01-.407.063z" fill="#4A4A4A" fill-rule="nonzero"/></svg>
                                                | 交付时间：{{pics.updated_at}}
                                            span.op(v-if="showDetail && pics.comment_content", @click="openCmt(pics.comment_content)")
                                                <svg class="icon" width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg"><path d="M11.082 7.38c.573-.565.917-1.274.917-2.047 0-1.666-1.594-3.043-3.67-3.29C7.671.844 6.13 0 4.332 0 1.939 0 0 1.492 0 3.333c0 .771.344 1.48.917 2.046A5.455 5.455 0 01.13 6.523a.48.48 0 00-.091.52.48.48 0 00.441.292c1.115 0 2.015-.42 2.608-.808.192.044.39.077.592.102.654 1.196 2.19 2.038 3.985 2.038.433 0 .85-.05 1.246-.142.593.385 1.491.808 2.608.808a.482.482 0 00.35-.812 5.31 5.31 0 01-.788-1.142zM2.9 5.455l-.356.231c-.294.19-.594.34-.898.446.056-.098.112-.202.166-.308l.323-.648-.516-.51C1.337 4.387 1 3.93 1 3.333 1 2.07 2.527 1 4.333 1c1.806 0 3.333 1.069 3.333 2.333 0 1.265-1.527 2.334-3.333 2.334-.344 0-.687-.04-1.02-.117L2.9 5.456zm7.48 1.21l-.514.509.323.648c.054.106.11.21.166.308a4.238 4.238 0 01-.898-.446l-.356-.23-.414.095a4.539 4.539 0 01-1.021.117c-1.125 0-2.129-.42-2.735-1.036 2.11-.225 3.735-1.612 3.735-3.298 0-.07-.009-.14-.015-.208C9.993 3.427 11 4.308 11 5.333c0 .598-.338 1.054-.619 1.334z" fill="#4A4A4A" fill-rule="nonzero"/></svg>
                                                | 查看修改意见
</template>

<script>
export default {
    name: '',
    props: {
        pics: {
            type: Array,
            default() {
                return [];
            }
        },
        showDetail: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number,
            default: 1
        }
    },
    methods: {
        openCmt(cont) {
            this.$alert(cont, '查看修改意见', {
                dangerouslyUseHTMLString: true
            });
        },
    }
};
</script>

<style lang="sass" scoped>
    .slider
        margin-left: -15px
        /deep/ .el-image__inner
            width: 236px
            height: 236px
            object-fit: contain
            box-shadow: 0 1px 4px 0 #E9EBF0
        .imgs-solid
            position: relative
            .new
                background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAMAAABU4iNhAAAAe1BMVEUAAAAjU9YsVdMzYOMgTtAzXNxhcP8gTtAnVdgmU9kgTtAgTtAhTtAiUdIgT88gTtAgTtAhTtAhTtAgTs8gTs8hT9AgT9AhT9AgT9AhT9EhUdEhT9IiUtIlT9EhT9AgT9AgT9AhT88hT9EhUNAjTtEjUdEiT9InTtggTs9puSgcAAAAKHRSTlMAFREG+goC6hMN9fGvJebd1s7GuZmSem5iUD01LR7iv6ehioNYSEQaseUPMAAAALBJREFUOMuNykeSwjAAAMGxZMk2YJa4iY1E//+FFGUojpo+Nw8xdSGEtq3u2jbcdF1KMcacAfrZfFoPBfURqmYoqz+ArY25LsfJiRsRz7g5+cTN6SNSijvcbHa42XzxVIhuznvc/Olx8+UbMcfo5uKCm4sKN5cVbi4Dbq4Cbq463PxNuLlOuLmOuDmLiDlGN/8ibv5n3Nxk3NyAm1vk3CPnATlfEcYo5xuKjgzvSDZeAe7Pd67LY7yKAAAAAElFTkSuQmCC') no-repeat left top
                position: absolute
                left: 0
                top: 0
                width: 46px
                height: 40px
                font-size: 10px
                padding-left: 2px
                padding-top: 2px
                color: #fff
        .imgs
            display: flex
            justify-content: center
            .img
                margin-right: 16px
        .time,.op
            text-align: left
            font-size: 12px
            color: #4A4A4A
            margin-left: 16px
            display: block
            // display: none
        .is-active
            .img
                box-shadow: 0 0px 5px #7C7C7C
        .img
            width: 236px
            height: 236px
            border-radius: 4px
            background-color: #fff
            box-shadow: 0 0px 4px #E9EBF0
            border-radius: 4px
        .el-carousel__item
            &.is-active
                display: block
                margin-left: 6px
                .time,.op
                    display: block
            &.is-active + .el-carousel__item
                transform-origin: right center
        /deep/ .el-carousel
            overflow-x: inherit
            .el-image__inner
                object-fit: contain
                box-shadow: 0 1px 4px 0 #E9EBF0
            .el-carousel__item
                display: none
                transform-origin: left center
                overflow: inherit
                padding: 5px 10px 0
                &.is-in-stage
                    display: block
                // &:first-of-type
                //     transform-origin: right center
            .el-carousel__indicators--outside
                margin-top: -40px
            .el-carousel__container
                // height: 272px
                margin-left: 18px
            .el-carousel__arrow
                margin-top: -10px
                background: #FFFFFF
                box-shadow: 0 1px 4px 0 #E9EBF0
                i
                    color: #4B8EFE
                    font-size: 22px
                    display: inline-block
                    vertical-align: middle
            .el-carousel__arrow--left
                left: -40px
            .el-carousel__arrow--right
                right: -35px
            .el-carousel__indicator
                &.is-active
                    .el-carousel__button
                        background-color: #2778FF
                .el-carousel__button
                    background: #D7D8E0
                    width: 8px
                    height: 8px
                    border-radius: 4px
</style>