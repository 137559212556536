<template lang="pug">
    .items-wrap(v-if="text")
        .item(v-bind:id="id")
            .i-title {{title}}
            .cont.txt
                pre.cont(v-html="text")
                pre.cont(v-if="text_desc") {{text_desc}}
</template>
<script>
export default {
    name: '',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        },
        text:{},
        text_desc:{},
        id:{},
        title:{}
    },
};
</script>
<style lang="sass" scoped>
    .txt
        background: #FFFFFF
        border: 1px solid #EAECEF
        border-radius: 4px
        font-size: 14px
        color: #696D74
        max-height: 800px
        overflow-y: scroll
        padding: 20px
        .p
            margin-bottom: 20px
            &:last-child
                margin-bottom: 0
        .sign
            font-size: 14px
            color: #1B1919
            margin-bottom: 20px
        .name
            color: #1B1919
            margin-bottom: 2px
        .cont

            padding: 20px
            overflow-y: scroll
            max-height: 500px
            border: 1px solid #CECECE
</style>