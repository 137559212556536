<template lang="pug">
    .items-wrap(v-if="desc_text")
        .item(id="client_like_file_desc")
            .cont.txt
                .p
                    pre.cont-desc {{desc_text}}

</template>

<script>
export default {
    name: '',
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            }
        },
        stage: {
            type: Number,
            default: 0
        },
        desc_text:{

        }
    }
};
</script>

<style lang="sass" scoped>

.txt
    margin-top: -85px
    background: #FFFFFF
    border: 1px solid #EAECEF
    border-radius: 4px
    font-size: 14px
    color: #696D74
    max-height: 268px
    overflow-y: scroll
    padding: 20px
    .p
        margin-bottom: 20px
        &:last-child
            margin-bottom: 0
    .sign
        font-size: 14px
        color: #1B1919
        margin-bottom: 20px
    .name
        color: #1B1919
        margin-bottom: 2px
    .cont-desc
        color: #696D74
        line-height: 18px
</style>