<template lang="pug">
    .page-pending
        .page-content
            .inner.mod-inner
                <Directory :businessInfo="businessInfo" :stage="stage" />
                .part-main
                  
                  //任务描述
                  <Title :businessInfo="businessInfo" :stage="stage" /> 

                    //查看原始需求
                    template(v-for="item in businessInfo.picture_types")
                        template(v-if="item.id == 9")
                                template(v-for="(pics, i) in item.pictures")
                                    .raw-btn(v-if="pics.attachment.pub_url")  查看原始需求
                                    .raw_image
                                        el-image.img(
                                            :src="pics.attachment.pub_url + '?x-oss-process=style/list'",
                                            :preview-src-list="[pics.attachment.pub_url]"
                                        )
                    //给自由职业的提示
                  <TextsToFreelancer :businessInfo="businessInfo" />
                    //其他特别要求
                  <OtherReqText :businessInfo="businessInfo" />


                  //偏好风格图片
                  <Pictures :pics="businessInfo.picture_types" :id="1" />
                  //偏好风格图片说明
                  <AssetPictureDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.client_like_picture_desc"/>
                
                    
                    template(v-for="item in businessInfo.picture_types")
                        template(v-if="item.id == 2")
                            //偏好风格文件
                            <Files v-if="businessInfo.picture_types" :pics="businessInfo.picture_types" :id="2" />
                            //偏好风格文件说明
                            <FilesAssetDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.client_like_file_desc"/>


                    template(v-for="item in businessInfo.picture_types")
                        template(v-if="item.id == 3")
                            //已有图片素材
                            <Pictures :pics="businessInfo.picture_types" :id="3" />
                                //已有图片素材说明
                            <AssetPictureDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.asset_picture_desc" />


                    template(v-for="item in businessInfo.picture_types")
                        template(v-if="item.id == 4")
                            //已有文件素材
                            <Files :pics="businessInfo.picture_types" :id="4" />
                            //已有文件素材说明
                            <FilesAssetDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.asset_file_desc"/>
                    
                    template(v-if="businessInfo.ticket.asset_text_desc")
                            //资料素材文本
                            <TextAsset :businessInfo="businessInfo" :text="businessInfo.text_asset" :text_desc="businessInfo.ticket.asset_text_desc" :id="'text_asset'" :title="title='素材资料文本'" />


                    template(v-for="item in businessInfo.picture_types")
                        template(v-if="item.id == 10")
                            //资料素材文件
                            <Files :pics="businessInfo.picture_types" :id="10" />
                            //资料素材文件说明
                            <FilesAssetDesc  :businessInfo="businessInfo" :desc_text="businessInfo.ticket.info_file_desc" />


                    template(v-for="item in businessInfo.picture_types")
                        template(v-if="item.id == 11")
                            //设计基础图片
                            <Pictures :pics="businessInfo.picture_types" :id="11" />
                            //已有图片素材说明
                            <AssetPictureDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.design_base_pic_desc" />


                    template(v-for="item in businessInfo.picture_types")
                        template(v-if="item.id == 12")
                            //设计基础文件
                            <Files :pics="businessInfo.picture_types" :id="12" />
                            //偏好风格文件说明
                            <FilesAssetDesc :businessInfo="businessInfo" :desc_text="businessInfo.ticket.desgin_base_file_desc"/>

                    template(v-if="businessInfo.ticket.text_base_text_raw")
                            //文字基础文本
                            <TextAsset :businessInfo="businessInfo" :text="businessInfo.ticket.text_base_text_raw" :text_desc="businessInfo.ticket.text_base_text_dec" :id="'text_base_text_raw'" :title="title='文字基础文本'" />

                    template(v-for="item in businessInfo.picture_types")
                        template(v-if="item.id == 13")
                            //文字基础文件
                            <Files :pics="businessInfo.picture_types" :id="13" />
                            //偏好风格文件说明
                            <FilesAssetDesc :businessInfo="businessInfo" :desc_text="businessInfo.text_base_file_desc"/>

                  <other-req :businessInfo="businessInfo" />
                  <Dates :dates="businessInfo.dates" />
</template>

<script>
import { Message } from 'element-ui';
import Api from '../../common/api';
import Directory from './components/directory';
import Dates from './components/dates'; 
import Title from './components/title';
import TextsToFreelancer from './components/text_to_freelancer'; 

import Files from './components/files'; 
import FilesAssetDesc from './components/files_asset_desc'; 

import Pictures from './components/pictures';
import AssetPictureDesc from './components/asset_picture_desc'; 

import TextAsset from './components/text_asset';

import OtherReq from './components/otherReq'; 
import OtherReqText from './components/otherReqText'; 



export default {
    name: 'Index',
    components: {
        Directory,
        Title, Files,  Pictures,  OtherReq, Dates,  AssetPictureDesc,TextAsset,FilesAssetDesc,OtherReqText,TextsToFreelancer
    },
    data() {
        return {
            businessInfo: {
                ticket:{
                    
                }
            },
            showSingle: '',
            stage: 1,
            id: '',
            flow_image_url : '',
            showViewer:false,
            is_have_guide:false,
            show_raw:false,
        }
    },
    watch: {
        $route() {
            this.init();
        }
    },
    created() {
        this.init();
    },
    mounted() {

    },
    methods:{
        init() {
            const { id, stage } = this.$route.params;
            if (id) {
                this.id = id;
                const loading = this.$loading({
                    text: '获取信息中...',
                });
                Api.get(`/api/tasks/${id}/${stage}`).then(data => {
                    this.businessInfo = data;



                    setTimeout(() => {
                        loading.close();
                    }, 500);
                }).catch(() => {
                    loading.close();
                });
            } else {
                Message({
                    message: '地址错误，请联系管理员！',
                    type: 'error'
                });
            }
        },
        switch_raw(){
            
            if(this.show_raw == false){
                this.show_raw = true;
            }else{
                this.show_raw = false;
            }

        },
        showSingleFn(txt) {
            console.log('txt', txt);
            this.showSingle = txt;
        },
        closeSingleFn() {
            this.showSingle = '';
        }
    }
};
</script>
<style lang="sass" scoped>
    .page-content
        padding-top: 113px
        .part-main
            margin: 0px auto
            width: 920px
            background: #FFFFFF
            box-shadow: 0 2px 9px 2px #E4E4E4
            position: relative
            z-index: 10
            padding: 0 70px
            .original-btn
                text-align: right
                margin-right: 20px
            
            .control
                border-top: 1px dashed #C6C6C6
                padding: 30px 0 140px
                .btn
                    border: 1px solid #CDCDCD
                    border-radius: 2px
                    width: 145px
                    height: 41px
                    line-height: 40px
                    display: inline-block
                    margin-right: 50px
                    margin-top: 30px
                    text-align: center
                    font-size: 14px
                    color: #696D74
                    cursor: pointer
                    .other-icon
                        margin-top: -3px !important
                        vertical-align: middle
                    .el-dropdown
                        color: #696D74
                    .icon
                        margin-right: 10px
                    .link
                        font-size: 12px
                    &:hover,&.current
                        .el-dropdown
                            color: #204ECF
                        color: #204ECF
                        border-color: #204ECF
            .items-wrap
                padding: 28px 20px
                /deep/ svg
                    display: inline-block
                    vertical-align: middle
                    margin-top: -4px
                    margin-right: 8px
                /deep/ .item
                    margin-bottom: 50px
                /deep/ .i-title
                    margin-bottom: 20px
                    font-weight: 400
                    font-size: 26px
                    color: #4A4A4A
                    text-align: center
    .raw_image
        // display: none
        position: absolute
        top: 200
        right: 0
        margin-right: 160px
        width: 20px
        height: 20px
        opacity: 0
        .img
            width: 100px
            height:  25px
    .raw-btn
        position: relative
        text-align: center
        margin-right: 20px
        width: 85px
        float: right
        border-bottom: 1px solid #6b6b6b87
        padding-bottom: 1px
        color: #6b6b6b
        cursor: pointer
        &:hover
            color: #000000
            border-bottom: 1px solid #6b6b6b


</style>