<template lang="pug">
    .items-wrap
        template(v-for="item in pics")
            template(v-if="item.id === id")
                .i-title {{item.name}}
                .items-wrap-inside
                    .item(:id="'picture_types_' + item.id")
                        template(v-for="(pics, i) in item.pictures")
                            .cont.file(v-if="pics.attachment")
                                span.new(v-if="showDetail && item.pictures.length === (i + 1)") New
                                .name
                                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.984 15.993H1.105S0 16.183 0 14.563V1.465S.025.008 1.376.008h4.863S6.83-.127 7.32.71c.467.81.737 1.323.737 1.323s.172.216.565.216h6.264s1.105-.135 1.105 1.215v11.18s.172 1.35-1.007 1.35zm-1.032-10.26c0-.325-.245-.595-.54-.595H2.604c-.32 0-.565.27-.565.594v.054c0 .351.245.622.565.622h10.808c.295 0 .54-.27.54-.622v-.054z" fill="#BCC7D9" fill-rule="nonzero"/></svg>
                                    | {{pics.attachment.file_name}}
                                span.download(v-if="showDetail", style="border:none") 交付时间：{{pics.updated_at}}
                                span.download(v-if="showDetail && pics.comment_content", @click="openCmt(pics.comment_content)") 查看修改意见
                                a.download(target="_blank",:href="pics.attachment.pub_url", download) 点击下载

                


</template>

<script>
export default {
    name: '',
    props: {
        pics: {
            type: Array,
            default() {
                return [];
            }
        },
        showDetail: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number,
            default: 1
        }
    },
    methods: {
        openCmt(cont) {
            this.$alert(cont, '查看修改意见', {
                dangerouslyUseHTMLString: true
            });
        },
    }
};
</script>

<style lang="sass" scoped>
    .items-wrap-inside
        border: 1px solid #EAECEF
        padding: 10px

    .file
        display: flex
        justify-content: space-between
        margin-bottom: 20px
        position: relative
        background: #FFFFFF
        border: 1px solid #EAECEF
        border-radius: 4px
        padding: 30px;
        font-size: 14px
        color: #696D74
        max-height: 400px
        overflow-y: scroll
        .new
            background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAMAAABU4iNhAAAAe1BMVEUAAAAjU9YsVdMzYOMgTtAzXNxhcP8gTtAnVdgmU9kgTtAgTtAhTtAiUdIgT88gTtAgTtAhTtAhTtAgTs8gTs8hT9AgT9AhT9AgT9AhT9EhUdEhT9IiUtIlT9EhT9AgT9AgT9AhT88hT9EhUNAjTtEjUdEiT9InTtggTs9puSgcAAAAKHRSTlMAFREG+goC6hMN9fGvJebd1s7GuZmSem5iUD01LR7iv6ehioNYSEQaseUPMAAAALBJREFUOMuNykeSwjAAAMGxZMk2YJa4iY1E//+FFGUojpo+Nw8xdSGEtq3u2jbcdF1KMcacAfrZfFoPBfURqmYoqz+ArY25LsfJiRsRz7g5+cTN6SNSijvcbHa42XzxVIhuznvc/Olx8+UbMcfo5uKCm4sKN5cVbi4Dbq4Cbq463PxNuLlOuLmOuDmLiDlGN/8ibv5n3Nxk3NyAm1vk3CPnATlfEcYo5xuKjgzvSDZeAe7Pd67LY7yKAAAAAElFTkSuQmCC') no-repeat left top
            position: absolute
            left: 0
            top: 0
            width: 46px
            height: 40px
            font-size: 10px
            padding-left: 2px
            padding-top: 2px
            color: #fff
        &:hover
            border-color: #204ECF
            color: #204ECF
            .download
                color: #204ECF
                border-color: #204ECF
        &::last-child
            margin-bottom: 0
        .download
            color: #1B1919
            border-bottom: 1px solid #CFCFCF
            text-decoration: none
            &:hover
                text-decoration: none
</style>